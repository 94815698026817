<template>
    <v-main>
        <v-container class="pb-12" :style="$mq != 'lg' ? 'max-width: 98% !important;' : 'max-width: 90% !important;'">
            <v-row>
                <v-col cols="12" class="py-0">
                    <h2
                        :style="{ color: parameters.primaryColor }"
                        class="text-left mb-3"
                        :class="$mq != 'lg' ? 'mt-3': 'mt-6'"
                    >
                        Meu carrinho
                    </h2>
                </v-col>
                <v-skeleton-loader
                    v-if="cart.loading"
                    v-bind="attrs"
                    height="100%"
                    width="100%"
                    type="card-heading, image, article, actions"
                    elevation="0"
                ></v-skeleton-loader>    
                <template v-else>
                    <CartEmpty v-if="itensOnCart == 0"/>
                    <template v-else>
                        <CartProductsListMobile v-if="$mq != 'lg'"/>
                        <CartProductsListDesktop v-else/>
                        <CartResume />
                    </template>
                </template>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
const CartProductsListMobile = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/cart/CartProductsListMobile.vue"
    );
const CartProductsListDesktop = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/cart/CartProductsListDesktop.vue"
    );
const CartEmpty = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/cart/CartEmpty.vue"
    );
const CartResume = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/cart/CartResume.vue"
    );
import { mapGetters } from 'vuex'

export default {
    data: () => ({
        attrs: {
            class: 'mb-6',
            tile: true,
        },
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        cart: {
            get() {
                return this.$store.state.cart;
            },
        },
        ...mapGetters({
            itensOnCart: 'cart/itensOnCart',
        })
    },
    components:{
        CartProductsListDesktop,
        CartProductsListMobile,
        CartEmpty,
        CartResume,
    },
   
};
</script>

<style>
</style>