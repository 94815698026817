<template>
    <v-col :cols="12" md="9" sm="12">
        <v-simple-table height="400px" fixed-header>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-center">PRODUTO</th>
                        <th class="text-center">PREÇO</th>
                        <th class="text-center">QUANTIDADE</th>
                        <th class="text-center">TOTAL</th>
                        <th class="text-center"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(saleLine, i) in salesLines" :key="i">
                        <td>
                            <v-list-item key="1">
                                <v-list-item-avatar>
                                    <v-img :src="saleLine.vd3foto"></v-img>
                                </v-list-item-avatar>

                                <v-list-item-content class="text-left">
                                    <v-list-item-title><a @click="openProduct(saleLine.vd2produto)">{{ saleLine.vd3desc | capitalize }}</a></v-list-item-title>
                                    <v-list-item-subtitle>{{ `${saleLine.vd2produto}-${saleLine.vd3opcao}-${saleLine.vd3tamanho}`}}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="caption d-flex flex-row align-center">
                                        <span>Cor </span>
                                        <v-card
                                            color="white"
                                            class="d-flex justify-center align-center mx-1 my-1"
                                            elevation="0"
                                            style="border: #dcdcdc 1px solid !important"
                                            dark
                                            max-width="25"
                                            height="25"
                                            width="25"
                                        >
                                            <v-img 
                                                v-if="saleLine.cr1imagem"
                                                :src="saleLine.cr1imagem"
                                                contain
                                                aspect-ratio="1"
                                                v-on:error="onImgError(saleLine)"
                                            />
                                            <v-card
                                                v-else
                                                dark
                                                :color="saleLine.hexadecimal"
                                                height="19"
                                                width="19"
                                                elevation="0"
                                            >
                                            </v-card>
                                        </v-card>
                                        <span>| Tamanho</span>
                                        <v-card
                                            color="white"
                                            class="d-flex justify-center align-center mx-1 my-1"
                                            elevation="0"
                                            style="border: #dcdcdc 1px solid !important"
                                            dark
                                            max-width="25"
                                            height="25"
                                            width="25"
                                        >
                                            <v-card
                                                dark
                                                color="transparent"
                                                class="d-flex align-center"
                                                height="19"
                                                width="19"
                                                elevation="0"
                                            >
                                                <div class="caption flex-grow-1 text-center" :style="{color: parameters.primaryColor}">{{ saleLine.vd3tamanho }}</div>
                                            </v-card>
                                        </v-card>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </td>
                        <td>{{ saleLine.vd5preco | money }}</td>
                        <td>
                            <input v-model="saleLine.vd3qtde" type="number" class="vd3qtde" :min="1" @change="change"/>
                        </td>
                        <td>{{ saleLine.vd3qtde * saleLine.vd5preco | money }}</td>
                        <td>
                            <v-btn icon small @click="removeProduct(i)">
                                <v-icon color="red" small>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-col>
</template>

<script>
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        cart: {
            get() {
                return this.$store.state.cart;
            },
        },
        salesLines: {
            get() {
                return this.$store.state.cart.salesLines;
            },
        },
    },
    methods:{
        openProduct(vd2produto){
            this.$router.push(`/produto/${vd2produto}`)
        },
        removeProduct(position){
            this.$store.dispatch("cart/remove", position)
        },
        change(){
            this.$store.dispatch("cart/save")
        },
        onImgError(saleLine){
            saleLine.cr1imagem = ""
        }
    }
};
</script>

<style>
</style>